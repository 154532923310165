import ROUTES from '../navigation/routes';
import { GET, POST } from './ConsumeApiController';

export async function GetLast()
{
    let response = await GET(ROUTES.EVENTOS.GetLast);
    return response;
}


export async function GetEventsByCity(idCiudad)
{
    let response = await GET(ROUTES.EVENTOS.GetByCity+idCiudad);
    return response;
}