import React from 'react';
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, TransitionProps, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ShopsProfile from "./profile";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function BusinessDialog(props)
{
    return(
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'fixed' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                        <Typography>Cerrar</Typography>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                <ShopsProfile/>
            </div>
        </Dialog>
    )
}