import React, { useEffect, useState } from 'react';
import { Card, CardActionArea, Grid, Typography } from "@mui/material"
import { useHistory } from "react-router-dom";
import ROUTES from "../navigation/routes"
import StyledRatingComponent from "./styledRating"
import BusinessDialog from '../views/website/empresas/BusinessDialog';

export default function ShopComponent(props)
{
    const [shops,setShops] = useState([]);
    const [open,setOpen] = useState(false);
    const history = useHistory();


    useEffect(()=>{
        getShops();
    },[]);

    const getShops = async () => {
        let _array = props.shops
        _array = await _array.filter(item=> item.categoria === props.idCategoria);
        setShops(_array);
        console.log('_array => ',_array);
    }

    const redireccionar = (route) => {
        history.push(route)
    }
    return(
        <Grid container spacing={3}>
        {   shops.length !== 0
            ?
            shops.map((shop,index) =>
                <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Card sx={{ maxWidth: '100%',position:'relative',marginBottom:5}} elevation={4}>
                        <CardActionArea onClick={()=>{
                            localStorage.setItem('ATECOMPANY',shop.id);
                            setOpen(true);
                        }}>
                            <div style={{display:'flex',flexDirection:'row',width:'100%',padding:5}}>

                                <div style={{width:100,height:100,marginRight:10}}>
                                    <img 
                                        style={{width:100,height:100,}}
                                        src={
                                            shop.imagen2 === null
                                            ?ROUTES.URLImagenes+'empresas/default.jpg'
                                            :ROUTES.URLImagenes+'empresas/'+ shop.imagen2.replace(/\s/g,'%20')
                                        }
                                        alt="Anzoategui Te Enamora"
                                    />
                                </div>
                                <div style={{marginRight:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {shop.negocio}
                                    </Typography>
                                </div>
                                <div style={{position:'absolute',bottom:5,right:10}}>
                                    <StyledRatingComponent value={shop.average} fontSize={15}/>
                                </div>
                            </div>
                        </CardActionArea>
                    </Card>
                </Grid>                
            )
            :
            <Grid item>
                <Typography style={{color:'#7d7d7d'}}>No se encontraron resultados</Typography>
            </Grid>
        }
        <BusinessDialog open={open} onClose={()=>setOpen(false)}/>
        </Grid>
 
    )
}