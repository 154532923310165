import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Slider, TextField, Typography } from '@mui/material';

import { GetShopById } from '../../../controllers/ShopsController';
import ROUTES from '../../../navigation/routes';

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import GoogleMapReact from 'google-map-react';

import RoomIcon from '@mui/icons-material/Room';

import { Facebook, Instagram, Phone, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import StyledRatingComponent from '../../../components/styledRating';
import { Carousel } from 'react-carousel-minimal';
import PersonIcon from '@mui/icons-material/Person';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

import MapMarker from '../../../components/MapMarker';
import marker from '../../../assets/images/MarkerATE.png';
import { useLocation, useParams } from 'react-router-dom';


import horarioBgImg from '../../../assets/images/horarios.png'
import corazones from '../../../assets/images/corazones.png';
import corazones_blancos from '../../../assets/images/corazones_blancos.png';
import telefono from '../../../assets/images/telefono.png';
import ubicacion from '../../../assets/images/ubicacion.png';

export default function ShopsProfile()
{
    const [shop,setShop] = useState([]);
    const [statics,setStatics] = useState([]);
    const [horarios,setHorarios] = useState([]);
    const [progress,setProgress] = useState(false);
    const [redes,setRedes] = useState([]);
    const [obligatorias,setObligatorias] = useState([]);
    const [fotos,setFotos] = useState([]);
    const [reviews,setReviews] = useState([]);
    const [sucursales,setSucursales] = useState([]);

    const { qrcode } = useParams();

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
        display:'none'
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }



    useEffect(()=>{
        window.scrollTo(0,0);
        getShop();
    },[])

    const render = (status) => {
        switch (status) {
          case Status.LOADING:
            return <CircularProgress />;
          case Status.FAILURE:
            return 'Error';
          case Status.SUCCESS:
            return <div>MAP</div>;
        }
      };

    const getShop = async () => {
        setProgress(true);
        let id = '';
        
        if(qrcode !== undefined)
            id = qrcode;
        else
            id = await localStorage.getItem('ATECOMPANY');
        
        let response = await GetShopById(id);

        if(response.success === true)
        {
            setShop(response.data.shop);
            setStatics(response.data.statics);
            setHorarios(response.data.horarios);
            setRedes(response.data.redes);
            setObligatorias(response.data.statics.obligatory);
            setReviews(response.data.reviews);
            setSucursales(response.data.sucursales);
            Fotos(response.data.fotos);
        }
        setProgress(false);
    }

    const Fotos = async (_fotos) => {
        let data = [];
        await _fotos.map(async (_foto)=>{
        
            await data.push({
                image: ROUTES.URLImagenes+'galeria/'+_foto.imagen,
                caption: 'Anzoategui Te Enamora'
            })
            return;
        });
        setFotos(data);
    }

    
    return(
        shop.length === 0
        ?
        <Box
            style={{
                display:'flex',
                height:'50vh',
                alignItems:'center',
                justifyContent:'center'
            }}
        >
            <CircularProgress color='primary'/>
        </Box>
        :
        <>
            <Box
                sx={{
                    display: 'flex',
                    height:'55vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 3,
                //backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+city.imagen + ")",  
                    backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'empresas/'+ shop.imagen + ")",
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100%',
                    position:'relative',
                    marginBottom:5
                }}
            >
                <Grid container spacing={3} style={{position:'absolute',bottom:20,padding:20}}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                        <Typography variant="h4" color="primary.contrastText" style={{fontWeight:'bold'}}>{shop.apellido}</Typography>
                        <Typography variant='caption' color="primary.contrastText" style={{fontWeight:'bolder'}}>
                            <RoomIcon color='secondary' style={{fontSize:14}}/>&nbsp;
                            {shop.direccion}
                        </Typography>&nbsp;&nbsp;&nbsp;
                        {
                            shop.idTipoEmpresa !== 10
                            ?
                            <Typography variant='caption' color="primary.contrastText" style={{fontWeight:'bolder'}}>
                                <Phone color='secondary' style={{fontSize:14}}/>&nbsp;
                                {shop.telefono}
                            </Typography>
                            :null
                        }
                    </Grid>
                        <Grid item xs={10} sm={10} md={4} lg={6} xl={6} style={{paddingRight:20}}>
                            <Grid container spacing={3} style={{display:'flex',flexDirection:'row-reverse'}}>
                                <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
                                    <Box
                                        sx={{
                                            boxShadow:'0px 0px 0px 4px rgb(255 255 255 / 20%)',
                                            color:'#FFF',
                                            background:'rgba(6,27,65,0.25)',
                                            padding:1,
                                            borderRadius:70,
                                            display:'flex',
                                            flexDirection:'row',
                                            width:'100%',
                                            alignItems:'center',
                                            '&:hover':{cursor:'pointer'},
                                            textDecoration:'none'
                                        }}
                                        component='a'
                                        href={'https://app.anzteenamora.com/review/?qrcode='+shop.qrcode+'&gateway=web'}
                                        target='_blank'
                                    >
                                        <Avatar
                                            sx={{width:56,height:56,bgcolor:'primary.main',}}
                                        >
                                            {parseFloat(statics.average).toFixed(1) || 0}
                                        </Avatar>
                                        <div style={{marginLeft:20,display:'flex',flexDirection:'column',alignItems:'center'}}>
                                            <StyledRatingComponent color='#FACC4A' fontSize={16} value={statics.average !== null ? parseFloat(statics.average).toFixed(1) : 0}/>
                                            <Typography variant='body2' color='primary.contrastText'>{statics.count || 0} Reseñas</Typography>
                                            <Typography variant='body2' color='#fdec3b'>Click para Reseñar</Typography>
                                        </div>
                                        {
                                            /**
                                             * box-shadow: 0px 0px 0px 4px rgb(255 255 255 / 20%);
                                                color: #fff;
                                                background: rgba(6,27,65,0.25);
                                            */
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                </Grid>
                
            </Box>
            
            <Container maxWidth="xl" sx={{marginBottom:5}}>
                <Grid container spacing={3}>
                    {/**COLUMNAS IZQUIERDA */}
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                        <Grid container spacing={3}>
                            {/**GALERIA DE FOTOS */}
                            {
                                fotos.length !== 0
                                ?
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Paper sx={{padding:2}}>
                                        <Typography>Galería de fotos</Typography>
                                        <div>
                                        <Carousel
                                            data={fotos}
                                            time={3000}
                                            width='100%'
                                            height='500px'
                                            captionStyle={captionStyle}
                                            radius='10px'
                                            slideNumber={true}
                                            slideNumberStyle={slideNumberStyle}
                                            captionPosition="bottom"
                                            automatic={true}
                                            dots={true}
                                            pauseIconColor="white"
                                            pauseIconSize="40px"
                                            slideBackgroundColor="darkgrey"
                                            slideImageFit="cover"
                                            thumbnails={true}
                                            thumbnailWidth="100px"
                                        />
                                        </div>
                                    </Paper>
                                </Grid>
                                :null
                            }
                            

                            {/** Descripcion */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{padding:2,position:'relative'}}>
                                    <div style={{position:'absolute',right:0}}>
                                        <img src={corazones} alt='corazones'/>
                                    </div>
                                    <TextField
                                        style={{width:'100%'}}
                                        id="standard-multiline-static"
                                        label=""
                                        multiline
                                        minRows={7}
                                        InputProps={{
                                            readOnly: true,
                                            
                                          }}
                                        value={shop.descripcion}
                                        variant="standard"
                                    />
                                </Paper>
                            </Grid>

                            

                            {/**RESENAS */}
                            
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Paper>
                                        <List
                                            sx={{width:'100%'}}
                                            subheader={
                                                <ListSubheader component="div">
                                                    Reseñas - { statics.count }
                                                </ListSubheader>
                                            }
                                        >
                                            <ListItem style={{marginBottom:20}}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={2} xl={2}>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{bgcolor:'primary.main',width:100,height:100,fontSize:30}}>
                                                                {parseFloat(statics.average).toFixed(1)}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                    </Grid>
                                                    <Grid item lg={10} xl={10}>
                                                        <Grid container spacing={3}>
                                                            {
                                                                statics.length !== 0
                                                                ?
                                                                statics.obligatory.map((obligatoria)=>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                        <Typography variant="caption">{obligatoria.question}</Typography>
                                                                        <LinearProgress
                                                                            style={{height:11}}
                                                                            variant="determinate"
                                                                            value={ parseFloat(obligatoria.average/5*100).toFixed(1)}
                                                                        />
                                                                    </Grid>
                                                                )
                                                                :null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            {
                                                reviews.length !== 0 && shop.comentarios !== 0
                                                ?
                                                reviews.map((review) =>
                                                    <ListItem style={{marginBottom:20}}>
                                                        <Grid container spacing={3}>
                                                            <Grid item md={3} lg={2} xl={2} style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center',alignSelf:'center'}}>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{ width: 65, height: 65 }}>
                                                                        <PersonIcon style={{fontSize:45}}/>
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                                                                <Grid container spacing={3}>
                                                                    <Card variant="outlined" sx={{width:'100%',bgcolor:'#F5F6FA'}}>
                                                                        <CardContent style={{color:'#878C9F'}}>
                                                                            <Typography style={{textAlign:'end'}}><StyledRatingComponent value={review.puntos} fontSize={17}/></Typography>
                                                                            <Typography>"{review.comentario}"</Typography>
                                                                            
                                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                            <Typography variant="caption">
                                                                                {review.created_at}
                                                                            </Typography>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                )
                                                :null
                                            }
                                        </List>
                                    </Paper>
                                </Grid>
                            
                        </Grid>
                    </Grid>

                    {/**COLUMNA DERECHA */}
                    {/**Horario - Ubicacion - Resenas */}
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Grid container spacing={3}>
                            {/**Horarios */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper elevation={4}>
                                    <List
                                        sx={{width:'100%',borderRadius:5}}
                                        subheader={
                                            <ListSubheader component="div" sx={{bgcolor:'#091B3E',color:'primary.contrastText',width:'100%',borderTopRightRadius:5,borderTopLeftRadius:5}}>
                                                <Typography style={{textAlign:'center',padding:10}}>Horario de Trabajo</Typography>
                                            </ListSubheader>
                                        }
                                    >
                                        <Box
                                            sx={{
                                                backgroundImage: "url(" + horarioBgImg + ")",
                                                backgroundPosition:'center',
                                                backgroundRepeat:'no-repeat',
                                                backgroundSize:'57%',
                                                position:'relative',
                                            }}
                                        >
                                        {
                                            horarios.length !== 0
                                            ?
                                            horarios.map( (horario) =>
                                                <ListItem style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                                    <Typography>{horario.dia}</Typography>
                                                    <Typography>{horario.inicio === 'null' ?'Cerrado' : horario.inicio} - {horario.fin === 'null' ?'Cerrado' : horario.fin}</Typography>
                                                </ListItem>
                                            )
                                            :null
                                        }
                                        </Box>
                                    </List>
                                </Paper>
                            </Grid>
                            {/** Ubicacion */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{bgcolor:'primary.main',color:'primary.contrastText',position:'relative'}}>
                                    <div style={{position:'absolute',right:0}}>
                                        <img src={corazones_blancos} style={{width:250}} alt='corazones'/>
                                    </div>
                                    <List
                                        sx={{width:'100%'}}
                                        subheader={
                                            <ListSubheader component="div" sx={{bgcolor:'#091B3E',color:'primary.contrastText',width:'100%',borderTopRightRadius:5,borderTopLeftRadius:5}}>
                                                <Typography style={{textAlign:'center',padding:10}}>Ubicación</Typography>
                                            </ListSubheader>
                                        }
                                    >
                                        
                                        {/**Mapa */}
                                        <ListItem>
                                            {
                                                shop.length !== 0
                                                ?
                                                <div style={{width:'100%',height:350,background:'#212121'}}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{ key: "AIzaSyCm6IHco-Ma5uDw9AqUpJm8BHkbGz4DkNU" }}
                                                        center={{lat: parseFloat(shop.latitud),lng: parseFloat(shop.longitud)}}
                                                        defaultZoom={sucursales.length !== 0 ? 13 :16}
                                                    >
                                                        <Box 
                                                            sx={{
                                                                '&hover':
                                                                {
                                                                    cursor:'pointer'
                                                                }
                                                            }}
                                                            lat={parseFloat(shop.latitud)}
                                                            lng={parseFloat(shop.longitud)}
                                                        >
                                                            <img
                                                                style={{
                                                                    width:40,height:50,
                                                                    position:'absolute',
                                                                    transform:'translate(-50%, -50%)'
                                                                }}

                                                                src={ROUTES.URLImagenes+'markers/'+shop.marker}
                                                                alt="Anzoategui Te Enamora"
                                                            />
                                                        </Box>
                                                        {
                                                            sucursales.length !== 0
                                                            ?
                                                            sucursales.map((sucursal)=>
                                                            <Box
                                                                lat = {parseFloat(sucursal.latitud)}
                                                                lng = {parseFloat(sucursal.longitud)}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width:40,height:50,
                                                                        position:'absolute',
                                                                        transform:'translate(-50%, -50%)'
                                                                    }}
                                                    
                                                                    src={ROUTES.URLImagenes+'markers/'+shop.marker}
                                                                    alt="Anzoategui Te Enamora"
                                                                />
                                                            </Box>
                                                            )
                                                            :null
                                                        }
                                                    </GoogleMapReact>
                                                </div>
                                                :null

                                            }
                                        </ListItem>
                                        <ListItem>
                                            <img src={ubicacion} style={{width:30}} alt='ubicacion'/>
                                            &nbsp; {shop.direccion}
                                        </ListItem>
                                        {
                                            shop.idTipoEmpresa !== 10
                                            ?
                                            <ListItem>
                                                <img src={telefono} style={{width:30}} alt='ubicacion'/> &nbsp; {shop.telefono}  
                                            </ListItem>
                                            :null
                                        }
                                        
                                        <ListItem>
                                            
                                        </ListItem>
                                            {
                                                redes.length !== 0
                                                ?
                                                redes.map((red)=>{
                                                    //if(red.plataforma !== 'YouTube')
                                                        return(
                                                            <ListItem disablePadding>
                                                                <ListItemButton component='a' href={red.enlace} target="_blank">
                                                                    <img
                                                                        src={ROUTES.URLImagenes+'redesSociales/'+red.imagen}
                                                                        alt={red.plataforma}
                                                                        style={{width:30}}
                                                                    /> &nbsp;&nbsp; 
                                                                    <ListItemText primary={red.username} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                            
                                                        )
                                                })
                                                :null
                                            }
                                    </List>
                                </Paper>
                            </Grid>

                            {/**Resenas */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper elevation={4}>
                                    <List
                                        sx={{width:'100%'}}
                                        subheader={
                                            <ListSubheader component="div">
                                                Su opinión es la clave para mejorar el servicio
                                            </ListSubheader>
                                        }
                                    >
                                        {
                                            obligatorias.length !== 0
                                            ?
                                            obligatorias.map((obligatoria)=>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{bgcolor:'primary.main'}}>
                                                            {parseFloat(obligatoria.average).toFixed(1)}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText secondary={obligatoria.question}/>
                                                </ListItem>
                                            )
                                            :<Typography style={{marginLeft:5}}>No hay Reseñas</Typography>
                                        }
                                        
                                    </List>
                                    <Box sx={{padding:5}}>
                                        <Button 
                                            fullWidth
                                            variant='contained'
                                            LinkComponent='a'
                                            href={'https://app.anzteenamora.com/review/?qrcode='+shop.qrcode+'&gateway=web'}
                                            target='_blank'
                                        >
                                            Presione aca para Reseñar
                                        </Button>
                                    </Box>
                                </Paper>
                            </Grid>
                            
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
        </>
    )
}