import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, ButtonBase, styled, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

import ROUTES from '../navigation/routes';


const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 130,
    },
    '& .MuiImageBackdrop-root':{
        opacity:0.3,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));

  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));


export default function CustomImageButton(props)
{
    const [open,setOpen] = useState(false);
    return(
        <Box>
            <ImageButton
                focusRipple
                //key={event.nombre+index}
                style={{width:'100%'}}
                onClick={()=>setOpen(true)}
                sx={{
                  '&:hover':{transform:'scale(1.1)'},
                }}
            >
                <ImageSrc style={{borderRadius:10, backgroundImage: `url(${ROUTES.URLImagenes+'eventos/'+ props.img.replace(/\s/g,'%20')})` }} />
                <ImageBackdrop style={{borderRadius:10}} className="MuiImageBackdrop-root" />
                <Image>
                    <Typography
                        component='span'
                        variant='subtitle1'
                        color='inherit'
                        sx={{
                            position: 'relative',
                            p: 4,
                            pt: 2,
                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            borderRadius:10
                        }}
                    >
                        {props.nombre}
                        <ImageMarked className="MuiImageMarked-root" />
                    </Typography>
                </Image>
            </ImageButton>
            <Dialog
                fullWidth
                maxWidth='xs'
                open={open}
                onClose={()=>setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll='body'
                sx={{borderRadius:20}}
            >
                <div style={{width:'100%'}}>
                    <img style={{width:'100%'}} src={ROUTES.URLImagenes+'eventos/'+ props.img.replace(/\s/g,'%20')} alt={props.nombre}/>
                </div>
                <DialogTitle id="alert-dialog-title">
                    {props.nombre}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ubicación: {props.ciudad}
                        <br/><br/>
                        {props.descripcion}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}