import React, { createContext, useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Card, CardActionArea, Container, Grid, Paper, Typography } from "@mui/material";


import Events from "./events";
import Shops from "./empresas";
import Cities from "./cities";
import { PlayCircle } from "@mui/icons-material";



import Gastronomia from "./gastronomia";
import Resenas from "./reviews";
import Products from "./products";
//import Instagram from "./redesSociales/instagram";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

//import ReactCSSTransitionGroup from 'react-transition-group';

//Google Analitics
import { GA_EventTracker } from '../../controllers/auxFunctions';
import Carousel from "../../components/Carousel";



import CountContext from "../../context/countContext";
import Categorias from "./categorias";

import { Facebook, Instagram, Phone, Twitter, YouTube, WhatsApp, Language, Room } from '@mui/icons-material';

import mapa from '../../assets/images/MAPA.png';
import calendario from '../../assets/images/CALENDARIO.png';
import corazones from '../../assets/images/corazones.png';
import corazones2 from '../../assets/images/corazones2.png';

import ciudadesPic from '../../assets/images/ciudades_vector.png';

import edicion1 from '../../assets/images/revista/edicion1.jpg'
import edicion2 from '../../assets/images/revista/edicion2.jpg'

import coverPic from '../../assets/images/ciudades.jpg';
import { GetTop5Shops } from "../../controllers/ShopsController";
import ROUTES from "../../navigation/routes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StyledRatingComponent from "../../components/styledRating";
import Categorias2 from "./categorias/index2";






export default function Home()
{
    const [top5,setTop5] = useState([]);

    const ciudades = [
        {nombre: 'Barcelona', id: 2, imagen: '-Barcelona.jpeg'},
        {nombre: 'Lechería', id: 1, imagen: '-Lechería.png'},
        {nombre: 'Puerto La Cruz', id: 3, imagen: 'PuertoLaCruz.jpeg'},
        {nombre: 'Guanta', id: 4, imagen: 'Guanta.jpeg'},
    ];

    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        handleGetTop5();
    },[]);

    const handleGetTop5 = async () => {
        let response = await GetTop5Shops();
        if(response.success === true)
        {
            setTop5(response.data.top5);
        }
    }

    const settings = {
        className: "center",
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 2000,
        autoplay:true,
        autoplaySpeed:3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

    return(
        <Box>
            <Box
                sx={{
                    display:'flex',
                    minHeight:'40vh',
                    maxHeight:'50vh',
                    width:'100%',
                    justifyContent:'center',
                }}
            >
                <img src={coverPic} style={{objectFit:'cover',width:'100%'}} alt='Anzoategui Te Enamora'/>
            </Box>

            {/** CATEGORIAS DE ALIADOS */}
            <Categorias2/>

            <Box mt={5} sx={{width:'98.7%'}}>
               
                <Typography component='h4' variant="h3" color='primary.main' sx={{fontWeight:'bold',textAlign:'center'}}>
                    Recomendados
                </Typography>
                <br/>
                {
                    top5.length !== 0 &&
                    <Slider {...settings}>
                        {
                        top5.map(item=>
                            <div style={{width:'100%',height:250}}>
                                <Paper elevation={3} sx={{m:5,borderRadius:10,'&:hover':{cursor:'pointer'}}} onClick={()=>redireccionar('/company/'+item.code)}>
                                    <Box
                                        sx={{
                                            width:'100%',height:250,borderRadius:5,
                                            bgcolor: 'background.default',
                                            color: 'text.primary',
                                            p: 3,
                                            backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'empresas/'+ item.imagen + ")",
                                            backgroundPosition:'center',
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'100%',
                                            position:'relative',
                                            marginBottom:5
                                        }}
                                    >
                                        <Grid container spacing={3} style={{position:'absolute',bottom:20,padding:20}}>
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Typography variant="h6" color="primary.contrastText" style={{fontWeight:'bold'}}>{item.comercio}</Typography>
                                                <Typography variant='caption' color="primary.contrastText" style={{fontWeight:'bolder'}}>
                                                    <Room color='secondary' style={{fontSize:14}}/>&nbsp;
                                                    {item.ciudad}
                                                </Typography>&nbsp;&nbsp;&nbsp;
                                            </Grid>
                                            {
                                                item.idTipoEmpresa !== 10
                                                ?
                                                <Grid item xs={10} sm={10} md={12} lg={6} xl={6} style={{paddingRight:20}}>
                                                    <Grid container spacing={3} style={{display:'flex',flexDirection:'row',position:'relative'}}>
                                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} position='absolute'>
                                                            <Box
                                                                sx={{
                                                                    boxShadow:'0px 0px 0px 4px rgb(255 255 255 / 20%)',
                                                                    color:'#FFF',
                                                                    background:'rgba(6,27,65,0.25)',
                                                                    padding:1,
                                                                    borderRadius:70,
                                                                    display:'flex',
                                                                    flexDirection:'row',
                                                                    width:150
                                                                }}
                                                            >
                                                                <Avatar
                                                                    sx={{width:36,height:36,bgcolor:'primary.main',}}
                                                                >
                                                                    <Typography variant="caption" sx={{fontWeight:'bold'}}>{parseFloat(item.avg).toFixed(1) || 0}</Typography>
                                                                </Avatar>
                                                                <div style={{marginLeft:20,display:'flex',flexDirection:'column'}}>
                                                                    <StyledRatingComponent color='#FACC4A' fontSize={16} value={item.avg !== null ? parseFloat(item.avg).toFixed(1) : 0}/>
                                                                    <Typography variant='caption' color='primary.contrastText'>{item.total || 0} Reseñas</Typography>
                                                                </div>
                                                                {
                                                                    /**
                                                                     * box-shadow: 0px 0px 0px 4px rgb(255 255 255 / 20%);
                                                                        color: #fff;
                                                                        background: rgba(6,27,65,0.25);
                                                                    */
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                :null
                                            }
                                            
                                        </Grid>
                                    </Box>
                                </Paper>
                            </div>
                        )
                    }
                    </Slider>
                }
            </Box>

            <Categorias/>


            

            {/**CIUDADES V-2.0 */}
            <Box 
                sx={{
                    mt:5,
                    position:'relative',
                    minHeight:'70vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'text.primary',
                    pt: 3,
                    pb: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'block',
                        position: 'absolute',
                        //left: 0,
                        right:0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.2,
                        backgroundImage: 'url('+mapa+')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '130% 0',
                        backgroundSize:'contain',
                    }}
                />
                <Container maxWidth="lg">
                    <Typography component='h3' variant="h2" color='primary.main' sx={{fontWeight:'bold', textAlign:'center'}}>
                        Ciudades
                    </Typography>
                    <Typography component='h6' variant="h6" sx={{fontWeight:'bold', textAlign:'center'}}>
                        En Anzoátegui tenemos los mejores destinos turísticos
                    </Typography>
                    
                    <Grid container spacing={3} mt={5}>
                            {
                                ciudades.map(item =>
                                    
                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                        <Card sx={{borderRadius:2}}>
                                            <CardActionArea onClick={()=>redireccionar('ciudad/'+item.id)}>
                                                <Box
                                                    sx={{
                                                        position:'relative',
                                                        height:200,
                                                        borderRadius:2,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        bgcolor: 'background.default',
                                                        color: 'text.primary',
                                                        p: 3,
                                                        //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                                        //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                                        backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+item.imagen + ")",
                                                        backgroundPosition:'center',
                                                        backgroundRepeat:'no-repeat',
                                                        backgroundSize:'cover',
                                                        '&:hover':{
                                                            WebkitTransform:'sclae(1.3)',
                                                            transform:'scale(1.3)',
                                                            transitionDuration:'1s'
                                                        }
                                                    }}
                                                >
                                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                                        <Typography component='h5' variant='h5' color='primary.contrastText'>{item.nombre}</Typography>
                                                    </div>
                                                </Box>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sm={6} md={4} lg={8} xl={8}>
                                <Card sx={{borderRadius:2}}>
                                    <CardActionArea onClick={()=>redireccionar('ciudades')}>
                                        <Box
                                            sx={{
                                                height:200,
                                                borderRadius:2,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'background.default',
                                                color: 'text.primary',
                                                p: 3,
                                                //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                                //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                                backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + coverPic + ")",
                                                backgroundPosition:'center',
                                                backgroundRepeat:'no-repeat',
                                                backgroundSize:'cover',
                                                '&:hover':{
                                                    WebkitTransform:'sclae(1.3)',
                                                    transform:'scale(1.3)',
                                                    transitionDuration:'1s'
                                                }
                                            }}
                                        >
                                            <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                                <Typography component='h5' variant='h5' color='primary.contrastText'>Todas</Typography>
                                            </div>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                    </Grid>
                </Container>
            </Box>

             

              {/**NUEVO DISENO */}
            {/**EVENTOS */}
            <Box 
                sx={{
                    mt:5,
                    position:'relative',
                    minHeight:'70vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'text.primary',
                    pt: 3,
                    pb: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'block',
                        position: 'absolute',
                        //left: 0,
                        right:0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.2,
                        backgroundImage: 'url('+calendario+')',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '0',
                        backgroundSize:'contain',
                    }}
                />
                
                <Events/>
            </Box>

            



            {/**NUEVO DISENO */}
            {/**ALIADOS 
            <Box sx={{mt:5,position:'relative'}}>
                <div style={{position:'absolute',right:0}}>
                    <img src={corazones} alt='corazones'/>
                </div>
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Typography component='h4' variant="h3" color='primary.main' sx={{fontWeight:'bold'}}>
                                Aliados
                            </Typography>
                            <Typography component='p' variant='h6'>
                                Anzoátegui Te Enamora tiene como objetivo promover y potenciar el sector
                                turístico de la Región así como registrar, valorar, reconocer y clasificar
                                todo lo bueno que se hace en el Estado. <br/>
                                Somos un canal abierto para los prestadores de servicios que deseen ser parte
                                del desarrollo y proyección de Anzoátegui, como potencia turística del país y
                                Latinoamérica. <br/><br/>
                                ¡Enamórate, conoce y disfruta la experiencia!
                            </Typography>
                            <Button variant='contained' sx={{mt:3,mb:3}} onClick={()=> history.push('/aliados')}>
                                Visita nuestros aliados
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <div style={{display:'flex',flex:1,justifyContent:'center',width:'100%'}}>
                                <img style={{width:'100%'}} src={mapa} alt='Anzoategui Te Enamora'/>
                            </div>
                        </Grid>
                    </Grid>
                </Container>                
            </Box>*/}



           
            
            


            {/**PUBLICIDAD
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    marginTop:3,
                    marginBottom:3,
                    height:{xs:110,sm:150,md:180,lg:200,xl:200}
                }}
            >
                <Container style={{height:'100%'}}>
                    <div style={{background:'#000',width:'100%',height:'100%'}}></div>
                </Container>
            </Box>*/}
           
           
            
            {/*
            <Turismo categoriaText={'Lugares Turísticos'} idCategoria={10} />
               */}

            <Box
                sx={{                   
                    mt:5,
                    p:5,
                    display:'flex',
                    flexDirection:'column',
                    background:'#2e3f6e',
                    color:'#FFF',
                    justifyContent:'center',
                    alignItems:'center'
                }}
            >
                <Typography variant='h4'>Prepárate para vivir experiencias emocionantes</Typography>
                <br/>
                <Button
                    sx={{
                        borderColor:'#FFF',
                        borderRadius:20,
                        background:'#FFF',
                        color:'primary',
                        '&:hover':{
                            background:'#FFF',
                            color:'#0288D1'
                        }
                    }}
                    variant="outlined"
                    size='large'
                    startIcon={<PlayCircle/>}
                    href="https://www.youtube.com/channel/UCNPUOohb4mj69gSCc4R6CXA"
                    target="_blank"
                >
                    Canal de YouTube
                </Button>
            </Box>     


            {/**Gastronomia /}
            <Gastronomia/>

            {/**Productos /}
            <Products/>

            {/**Reviews */}
            <Resenas/>

            

            {/**Revista */}
            <Box sx={{mb:5}}>
                <Typography
                    style={{textAlign:'center',marginTop:30,marginBottom:40,fontWeight:'bold'}}
                    variant='h3'
                    color='primary.main'
                >
                    Revista
                </Typography>
                <Container>
                    <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card>
                            <CardActionArea LinkComponent='a' target='_blank' href='https://anzteenamora.com/revista/revista_edicion2.pdf'>
                                <Box
                                    sx={{
                                        height:450,
                                        width:'auto',
                                        borderRadius:2,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'background.default',
                                        color: 'text.primary',
                                        p: 3,
                                        //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                        //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                        backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + edicion2 + ")",
                                        backgroundPosition:'center',
                                        backgroundRepeat:'no-repeat',
                                        backgroundSize:'auto',
                                    }}
                                >
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                        
                                        <Typography component='h5' variant='h5' color='primary.contrastText'>Click para leer la edición #2</Typography>
                                        
                                    </div>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card>
                            <CardActionArea LinkComponent='a' target='_blank' href='https://anzteenamora.com/revista/revista_edicion1.pdf'>
                                <Box
                                    sx={{
                                        height:450,
                                        width:'auto',
                                        borderRadius:2,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'background.default',
                                        color: 'text.primary',
                                        p: 3,
                                        //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                        //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                        backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + edicion1 + ")",
                                        backgroundPosition:'center',
                                        backgroundRepeat:'no-repeat',
                                        backgroundSize:'auto',
                                    }}
                                >
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                        
                                        <Typography component='h5' variant='h5' color='primary.contrastText'>Click para leer la edición #1</Typography>
                                        
                                    </div>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    </Grid>
                </Container>
            </Box>

        </Box>
    )
}