import ROUTES from '../navigation/routes';
import { GET, POST } from './ConsumeApiController';

export async function GetShopsByCategory(id)
{
    let response = await GET(ROUTES.SHOPS.GetShops+id);
    return response;
}

export async function GetShopsByCategoryName(name)
{
    let response = await GET(ROUTES.SHOPS.GetShopsByCategoryName+name);
    return response;
}

export async function GetShopById(id)
{
    console.log('id',id)
    const params = [
        {name:'company',value:id}
    ];
    let response = await POST(ROUTES.SHOPS.GetShop,params);
    return response;
}


export async function GetShopsByCity(idCiudad)
{
    let response = await GET(ROUTES.SHOPS.GetShopsByCity+idCiudad)
    return response;
}


export async function GetTop5Shops()
{
    let response = await GET(ROUTES.SHOPS.Top5);
    return response;
}



export async function GetShopSubCategories(idUsuario)
{
    let response = await GET(ROUTES.MENU.GetSubCategories+idUsuario);
    return response;
}

export async function GetShopSubCategoriesProducts(idUsuario,idSubCategoria)
{
    console.log('LA RUTA ES ===> ',ROUTES.MENU.GetShopCategoriesProducts+idUsuario+'/'+idSubCategoria);
    let response = await GET(ROUTES.MENU.GetShopCategoriesProducts+idUsuario+'/'+idSubCategoria);
    return response;
}