//useAnalyticsEventTracker.jsx

export function GA_EventTracker(event)
{

    let dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag({event:event});

    //gtag('config', 'G-R7XK8HY9R8',{ 'debug_mode':true });
    gtag('config', 'G-R7XK8HY9R8');

}
