import { Box } from "@mui/system";

import backgroundImg from '../../../assets/images/eventos.jpg' //'../assets/images/mapaRevista.jpg';
import Events from ".";

export default function Eventos()
{
    return(
        <div>
            <Box
                sx={{
                    display: 'flex',
                    height:{xs:'20vh',sm:'40vh',md:'40vh',lg:'40vh',xl:'40vh'},
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 3,
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(" + backgroundImg + ")",
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100%'
                }}
            >
                
            </Box>
            
            <Events/>
        </div>
    )
}