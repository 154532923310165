import { Container, Box, Typography, Grid, Button, Card, CardActionArea } from "@mui/material";

import backgroundImg from '../../../assets/images/revista.jpg' //'../assets/images/mapaRevista.jpg';


import edicion1 from '../../../assets/images/revista/edicion1.jpg'
import edicion2 from '../../../assets/images/revista/edicion2.jpg'


export default function Revista()
{
    return(
        <div>
            <Box
                sx={{
                    display: 'flex',
                    height:{xs:'20vh',sm:'40vh',md:'40vh',lg:'40vh',xl:'40vh'},//'40vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 3,
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(" + backgroundImg + ")",
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100%'
                }}
            >
                
            </Box>
            <Container sx={{paddingBottom:5}}>
                <Typography
                    style={{textAlign:'center',marginTop:30,marginBottom:40,fontWeight:'bold'}}
                    variant='h3'
                    color='primary.main'
                >
                    Revista
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography component='p' variant='h6' style={{textAlign:'justify'}}>
                            Anzoátegui Te Enamora Revista, es un producto editorial bien pensado, 
                            que tiene como objetivo promover el desarrollo del turismo en nuestro 
                            Estado, así como potenciar el trabajo de todas las empresas que hacen 
                            vida en la región, mostrando los productos y servicios que prestan. 
                            Sin duda, esto les ayudará a tener más proyección a todo nivel.
                            <br/><br/>
                            Para nosotros, es un honor ser un medio impreso y digital, para dar a 
                            conocer todas las bondades increíbles que tenemos en el territorio 
                            anzoatiguense, y estamos agradecidos con Dios por permitirnos llevar a 
                            cabo este proyecto hermoso, que va de la mano con la responsabilidad que 
                            como la marca del Estado, tenemos con Anzoátegui y nuestra gente.
                            <br/><br/>
                            Esta primera edición de Anzoátegui Te Enamora Revista, es una muestra de 
                            todo lo que vendrá en las próximas publicaciones que llegarán a sus manos 
                            cada tres meses. Estamos felices, motivados y comprometidos con hacer un 
                            gran trabajo y contribuir con todo lo bueno y hecho en nuestro Estado.
                            <br/><br/>
                            Gracias a Dios por cada paso que damos con su guía, y gracias a todas las 
                            personas que hicieron posible la materialización de este maravilloso proyecto.
                            <br/><br/>
                            ¡Somos Anzoátegui Te Enamora!
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card>
                            <CardActionArea LinkComponent='a' target='_blank' href='https://anzteenamora.com/revista/revista_edicion2.pdf'>
                                <Box
                                    sx={{
                                        height:450,
                                        width:'auto',
                                        borderRadius:2,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'background.default',
                                        color: 'text.primary',
                                        p: 3,
                                        //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                        //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                        backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + edicion2 + ")",
                                        backgroundPosition:'center',
                                        backgroundRepeat:'no-repeat',
                                        backgroundSize:'auto',
                                    }}
                                >
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                        
                                        <Typography component='h5' variant='h5' color='primary.contrastText'>Click para leer la edición #2</Typography>
                                        
                                    </div>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Card>
                            <CardActionArea LinkComponent='a' target='_blank' href='https://anzteenamora.com/revista/revista_edicion1.pdf'>
                                <Box
                                    sx={{
                                        height:450,
                                        width:'auto',
                                        borderRadius:2,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'background.default',
                                        color: 'text.primary',
                                        p: 3,
                                        //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                        //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                        backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + edicion1 + ")",
                                        backgroundPosition:'center',
                                        backgroundRepeat:'no-repeat',
                                        backgroundSize:'auto',
                                    }}
                                >
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                        
                                        <Typography component='h5' variant='h5' color='primary.contrastText'>Click para leer la edición #1</Typography>
                                        
                                    </div>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>

                
            </Container>
        </div>
    )
}