import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Card, CardActionArea, CardMedia, Grow, CircularProgress, Paper, Button, Divider, Item, Stack } from '@mui/material';
import { GetAllCities } from '../../../controllers/CitiesControllers';
import ROUTES from '../../../navigation/routes';
import { Box, height } from '@mui/system';

import w3 from '../../../assets/images/w3.jpg'
import { useHistory } from 'react-router-dom';
import { GA_EventTracker } from '../../../controllers/auxFunctions';

export default function Cities(props)
{
    const [cities,setCities] = useState([]);
    const [grow,,setGrow] = useState(props.grow);
    const [showProgress,setShowProgress] = useState(false);
    const history = useHistory();

    useEffect(()=>{
        getCities();
        //console.log(window.scrollY)
    },[])

    const getCities = async () => {
        setShowProgress(true);
        let response = await GetAllCities();
        setCities(response.data.ciudades);
        setShowProgress(false);
    }

    const setCity = async (city) => {
        await localStorage.setItem('ATE_CITY',JSON.stringify(city));
        GA_EventTracker(city.nombre);
        history.push('/ciudad');
    }

    return(
        <Box
            sx={{
                display: 'flex',
                minHeight:'100vh',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
                color: 'text.primary',
                p: 3,
                
            }}
        >
            <Container style={{marginBottom:50}}>
                <Typography component='h2' variant="h2" color='primary.main' style={{textAlign:'center',fontWeight:'bold',marginBottom:20}}>
                    Ciudades
                </Typography>
                <Typography component='h6' variant="h6" style={{textAlign:'center',marginBottom:40}}>
                    Recorre con nosotros esos destinos de nuestro Estado <br/> que te permitirán tener recuerdos memorables
                </Typography>

                <Grid container maxWidth='lg' spacing={3}>
                    {
                        showProgress === true
                        ?
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',marginTop:20}}>
                            <CircularProgress/>
                        </div>
                        :
                        cities.length !== 0 &&
                        cities.map((item)=>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <Card sx={{borderRadius:2}}>
                                    <CardActionArea onClick={()=>setCity(item)}>
                                        <Box
                                            sx={{
                                                height:200,
                                                borderRadius:2,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'background.default',
                                                color: 'text.primary',
                                                p: 3,
                                                //linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
                                                //linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 33%) 100%)
                                                backgroundImage: "linear-gradient(to bottom, rgb(6 27 65 / 0%) 0%,rgb(6 27 65 / 63%) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+item.imagen + ")",
                                                backgroundPosition:'center',
                                                backgroundRepeat:'no-repeat',
                                                backgroundSize:'cover',
                                            }}
                                        >
                                            <div style={{display:'flex',flexDirection:'column',justifyContent:'end',height:'100%'}}>
                                                
                                                <Typography component='h5' variant='h5' color='primary.contrastText'>{item.nombre}</Typography>
                                                
                                            </div>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    }
                </Grid>

            </Container>
        </Box>
    );
}