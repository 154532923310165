import React, { useState } from 'react';
import { Box, Button, Card, CardActionArea, Modal, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import marker from '../assets/images/MarkerATE.png';
import ROUTES from '../navigation/routes';
import StyledRatingComponent from './styledRating';
import BusinessDialog from '../views/website/empresas/BusinessDialog';

export default function MapMarker(props)
{
    const [show,setShow] = useState(false);
    const [open,setOpen] = useState(false);
    
    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    return(
        <div>
        <Box 
            sx={{
                '&hover':
                {
                    cursor:'pointer'
                }
            }}
            lat={parseFloat(props.shop.latitud)}
            lng={parseFloat(props.shop.longitud)}

            onClick={()=>setShow(true)}
        >
            <img
                style={{
                    width:40,height:50,
                    position:'absolute',
                    transform:'translate(-50%, -50%)'
                }}

                src={ROUTES.URLImagenes+'markers/'+props.shop.marker}
                alt="Anzoategui Te Enamora"
            />
        </Box>
        {
                show === true
                ?
                <Card sx={{ width: 400,position:'relative'}} elevation={4}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Typography variant='caption' style={{padding:5}}>
                            {props.ciudad}
                        </Typography>
                        <Button onClick={()=>setShow(false)}>
                            X
                        </Button>
                    </div>
                    <CardActionArea onClick={()=>{
                        localStorage.setItem('ATECOMPANY',props.shop.id)
                        redireccionar('/company/'+props.shop.id)
                    }}>
                        <div style={{display:'flex',flexDirection:'row',width:'100%',padding:5}}>

                            <div style={{width:100,height:100,marginRight:10}}>
                                <img 
                                    style={{width:100,height:100,}}
                                    src={
                                        props.shop.imagen2 === null
                                        ?ROUTES.URLImagenes+'empresas/default.jpg'
                                        :ROUTES.URLImagenes+'empresas/'+ props.shop.imagen2.replace(/\s/g,'%20')
                                    }
                                    alt="Anzoategui Te Enamora"
                                />
                            </div>
                            <div style={{marginRight:1}}>
                                <Typography gutterBottom variant="h6" component="div">
                                    {props.shop.negocio}
                                </Typography>
                                <div style={{overflow:'hidden',height:41}}>
                                <Typography variant="caption" color="text.secondary" flexWrap={true}>
                                    {props.shop.descripcion}
                                </Typography>
                                </div>
                            </div>
                            <div style={{position:'absolute',bottom:5,right:10}}>
                                <StyledRatingComponent value={props.shop.average} fontSize={15}/>
                            </div>
                        </div>
                    </CardActionArea>
                </Card>
                :null
            }
            <BusinessDialog open={open} onClose={()=>setOpen(false)}/>
        </div>
    )
}