const darkTheme = {
    palette : 
    {
        primary : 
        {
            light: '#202126',
            main : '#181A20',//'#0b0e11',
            dark: '#0b0e11',
            contrastText : '#fff',
        },
        secondary : 
        {
            light: '#ffdf5c',
            main : '#FCD535',//'#0b0e11',
            dark: '#face1b',
            contrastText : '#000',
        },
        text:
        {
            primary:'#E0E0E0',
            secondary:'#B7BDC6',
            primaryTitle:'#EAECEF',
            primaryText:'#B7BDC6'
        },
        background : 
        {
            default:'#0b0e11',
            main:'#181A20',
            paper : '#0b0e11',//0B0E11
            paperDark : '#0B0E11',
            contrastBg:'#FCD535',
            appBar:'#181A20'
        }

    },
    components:
    {
        MuiPaper:
        {
            styleOverrides:{
                root:{}
            }
        }
    }
}

export default darkTheme;