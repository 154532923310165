import { useEffect, useState } from "react";
import { Avatar, Box, CardContent, CircularProgress, Container, Grid, Paper, Typography } from "@mui/material";

import ROUTES from "../../../navigation/routes";

import RoomIcon from '@mui/icons-material/Room';

import { Facebook, Instagram, Phone, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import StyledRatingComponent from '../../../components/styledRating';
import { Carousel } from 'react-carousel-minimal';
import PersonIcon from '@mui/icons-material/Person';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useHistory, useParams } from "react-router-dom";
import { GetShopSubCategories } from "../../../controllers/ShopsController";



export default function MenuDigital()
{
    const [shop,setShop] = useState([]);
    const [categories,setCategories] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const { qrcode } = useParams();
    const history = useHistory();

    useEffect(()=>{
        handleGetCategories();
    },[]);

    const redireccionar = (route) => {
        history.push(route)
    }

    const handleGetCategories = async () => {
        setIsLoading(true);
        let response = await GetShopSubCategories(qrcode);
        if(response.success === true)
        {
            setShop(response.data.shop);
            setCategories(response.data.categorias);
        }
        setIsLoading(false);
    }


    return(
        <Box sx={{mb:5}}>
            {
                isLoading === true
                ?
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress/>
                </Box>
                :
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height:'35vh',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'background.default',
                            color: 'text.primary',
                            p: 3,
                        //backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+city.imagen + ")",  
                            backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'empresas/'+ shop.imagen + ")",
                            backgroundPosition:'center',
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'100%',
                            position:'relative',
                            marginBottom:5
                        }}
                    >
                        
                    </Box>
                    <Typography variant="h4" component="h4" textAlign="center">Menú Digital</Typography>
                    <Typography variant="h6" component="h6" textAlign="center">echa un vistazo a nuestra categorías</Typography>

                    <Container maxWidth="lg">
                    <Grid container spacing={3}>
                    {
                        categories.length !== 0 &&
                        categories.map(item=>
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <Paper
                                    elevation={3}
                                    sx={{'&:hover':{bgcolor:'#f0f0f0'}}}
                                    onClick={()=>redireccionar('/company/'+qrcode+'/menu/'+item.idSubCategoria)}
                                >
                                    <CardContent>
                                        <Typography sx={{fontWeight:'bold',fontSize:18}} textAlign='center'>{item.nombre}</Typography>
                                    </CardContent>
                                </Paper>
                            </Grid>
                        )
                    }
                    </Grid>
                    </Container>
                </Box>
            }
            
        </Box>
    )
}