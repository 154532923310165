import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Card, CardActionArea, Badge, CardActions, CardContent, CardMedia, CircularProgress, Container, Grid, Typography, Paper, Box } from "@mui/material";
import { GetShopsByCategory, GetShopsByCategoryName } from "../../../controllers/ShopsController";
import ROUTES from "../../../navigation/routes";

import StyledRatingComponent from '../../../components/styledRating';
import BusinessDialog from "./BusinessDialog";


export default function Shops(props)
{
    const [shops,setShops] = useState([]);
    const [showProgress,setShowProgress] = useState(false);

    const [open,setOpen] = useState(false);
    
    const history = useHistory();

    const redireccionar = (route) => {
        history.push(route)
    }

    useEffect(()=>{
        setShowProgress(false);
        getShops(props.category);
    },[props]);

    const getShops = async (category) => {
        await setShowProgress(true)
        let response = await GetShopsByCategoryName(category);
        setShops(response.data.negocios);
        await setShowProgress(false);
    }


    const selectBusiness = (id) => {
        //localStorage
    }


    const handleOpenCompany = async (shop_id,status,qrcode) => {
        if(status === 2)
        {
            window.open('https://app.anzteenamora.com/review/?qrcode='+qrcode+'&gateway=web');
        }
        else
        {
            localStorage.setItem('ATECOMPANY',shop_id);
            setOpen(true);
            //redireccionar('/company/'+shop.id);
        }
    }


    
    return(
        <Box style={{paddingBottom:40}}>
            
            {
                shops.length !== 0 &&
                <>
                <Typography style={{textAlign:'center',marginTop:30,marginBottom:20,fontWeight:'bold'}} variant='h4'>
                    Listado de {props.category}
                </Typography>
                <Grid container spacing={3} justifyContent='center'>
                    
                    {
                        shops.map((shop,index) =>
                            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4} style={{width:'100%'}}>
                                <Card sx={{width:'100%',position:'relative'}} elevation={4}>
                                    <CardActionArea onClick={()=>{
                                        handleOpenCompany(shop.id,shop.estatus,shop.qrcode)
                                    }}>
                                        <div style={{display:'flex',flexDirection:'row',width:'100%',padding:5}}>

                                            <div style={{width:100,height:100,marginRight:10}}>
                                                <img 
                                                    style={{width:100,height:100,}}
                                                    src={
                                                        shop.imagen2 === null
                                                        ?ROUTES.URLImagenes+'empresas/default.jpg'
                                                        :ROUTES.URLImagenes+'empresas/'+ shop.imagen2.replace(/\s/g,'%20')
                                                    }
                                                    alt="Anzoategui Te Enamora"
                                                />
                                            </div>
                                            <div style={{alignSelf:'center'}}>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                    sx={{
                                                        padding:2,
                                                        fontSize:{xs:15}}}
                                                >
                                                    {shop.negocio}
                                                </Typography>
                                            </div>
                                            <div style={{position:'absolute',bottom:5,right:10}}>
                                                <StyledRatingComponent value={shop.average} fontSize={15}/>
                                            </div>
                                        </div>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    }
                </Grid>
                </>
            }
            <BusinessDialog open={open} onClose={()=>setOpen(false)}/>
        </Box>
    )
}


/**
 * <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Lizard
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
      </CardActions>
    </Card>
 */