import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import { WebSiteLayout } from '../layouts/WebSiteLayout';
import Home from '../views/website';
import About from '../views/website/about';
import ShopsProfile from '../views/website/empresas/profile';
import City from '../views/website/cities/detail';
import Aliados from '../views/website/empresas/aliados';
import Eventos from '../views/website/events/eventos';
import Ciudades from '../views/website/cities/index2';
import Revista from '../views/website/revista';
import Reviews from '../views/website/reviews/resenas';
import MenuDigital from '../views/website/menuDigital';
import MenuDigitalProducts from '../views/website/menuDigital/products';



export default function WebSiteNavigation()
{
 
    
    return(
        <Switch>
            <AppRoute exact path='/' layout={WebSiteLayout} component={Home} />
            <AppRoute path='/aliados' layout={WebSiteLayout} component={Aliados} />
            <AppRoute path='/resenas' layout={WebSiteLayout} component={Reviews} />
            <AppRoute path='/revista' layout={WebSiteLayout} component={Revista} />
            <AppRoute path='/eventos' layout={WebSiteLayout} component={Eventos} />
            <AppRoute path='/ciudades' layout={WebSiteLayout} component={Ciudades} />
            <AppRoute path='/ciudad/:idCiudad' layout={WebSiteLayout} component={City} />
            <AppRoute path='/nosotros' layout={WebSiteLayout} component={About} />
            <AppRoute exact path='/company/:qrcode' layout={WebSiteLayout} component={ShopsProfile} />
            <AppRoute exact path='/company/:qrcode/menu' layout={WebSiteLayout} component={MenuDigital} />
            <AppRoute exact path='/company/:qrcode/menu/:category' layout={WebSiteLayout} component={MenuDigitalProducts} />

            <AppRoute path='/ciudad' layout={WebSiteLayout} component={City} />
        </Switch>
    )
}
