import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, ButtonBase, styled, Box, CircularProgress } from "@mui/material";
import { GetLast } from '../../../controllers/EventsController';
import CustomImageButton from '../../../components/ImageButton';


export default function Events()
{
    const [events,setEvents] = useState([]);
    const [showProgress,setShowProgress] = useState(false);

    useEffect(() => {
        getEvents();
    },[])

    const getEvents = async () => {
        setShowProgress(true)
        let response = await GetLast();
        if(response.success === true)
        {
            setEvents(response.data.eventos)
        }
        setShowProgress(false);
    }

    return(
        <Container style={{paddingBottom:30}} id='eventos'>
            <Typography component='h3' variant="h2" color='primary.main' sx={{fontWeight:'bold', textAlign:'center'}}>
                Eventos
            </Typography>
            <Grid container spacing={3} justifyContent='center'>
                <Grid item lg={8}>
                    <Typography component='p' variant='h6' textAlign='center'>
                        Anzoátegui Te Enamora brinda acompañamiento a los eventos que 
                        se realizan en el Estado y que tienen relevancia tanto a nivel 
                        regional como internacional. <br/><br/>
                        ¡Enamórate, conoce y disfruta la experiencia!
                    </Typography>
                </Grid>
            </Grid>
            {
                showProgress === true
                ? <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}> <CircularProgress /> </div>    
                :
                events.length === 0
                ? <Typography style={{textAlign:'center'}}>No se encontraron Eventos</Typography> 
                :
                <>
                    {
                        events &&
                        <Grid container spacing={3} mt={5} justifyContent='center'>
                            {
                                events.map((event,index) =>
                                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                                        <CustomImageButton
                                            nombre={event.nombre}
                                            img={event.img}
                                            ciudad={event.ciudad}
                                            descripcion={event.descripcion}
                                        />
                                    </Grid>
                                )
                            }                           
                        </Grid>
                    }
                </>
            }
        </Container>
    )
}