import { useEffect, useState } from "react";
import { Avatar, Box, CardContent, CircularProgress, Container, Dialog, Grid, IconButton, Paper, Typography } from "@mui/material";

import ROUTES from "../../../navigation/routes";

import RoomIcon from '@mui/icons-material/Room';

import { Facebook, Instagram, Phone, Twitter, YouTube, WhatsApp, Language } from '@mui/icons-material';
import StyledRatingComponent from '../../../components/styledRating';
import { Carousel } from 'react-carousel-minimal';
import PersonIcon from '@mui/icons-material/Person';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useHistory, useParams } from "react-router-dom";
import { GetShopSubCategoriesProducts, GetShopSubproductsProducts } from "../../../controllers/ShopsController";

import CloseIcon from '@mui/icons-material/Close';


export default function MenuDigitalProducts()
{
    const [shop,setShop] = useState([]);
    const [products,setProducts] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [product,setProduct] = useState([]);
    const [open,setOpen] = useState(false);

    const { qrcode, category } = useParams();
    const history = useHistory();

    useEffect(()=>{
        handleGetProducts();
    },[]);

    const redireccionar = (route) => {
        history.push(route)
    }

    const handleGetProducts = async () => {
        setIsLoading(true);
        let response = await GetShopSubCategoriesProducts(qrcode,category);
        if(response.success === true)
        {
            setShop(response.data.shop);
            setProducts(response.data.productos);
        }
        setIsLoading(false);
    }


    return(
        <Box sx={{mb:5}}>
            {
                isLoading === true
                ?
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <CircularProgress/>
                </Box>
                :
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            height:'35vh',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'background.default',
                            color: 'text.primary',
                            p: 3,
                        //backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'ciudades/'+city.imagen + ")",  
                            backgroundImage: "linear-gradient(to bottom,rgba(6,27,65,0) 0%, rgba(6,27,65,0.95) 100%),url(" + ROUTES.URLImagenes+'empresas/'+ shop.imagen + ")",
                            backgroundPosition:'center',
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'100%',
                            position:'relative',
                            marginBottom:5
                        }}
                    >
                        
                    </Box>
                    <Typography variant="h4" component="h4" textAlign="center">Menú Digital</Typography>
                    <Typography variant="h6" component="h6" textAlign="center">echa un vistazo a nuestros productos</Typography>

                    <Container maxWidth="lg">
                    <Grid container spacing={3}>
                        {
                            products.length !== 0 &&
                            products.map(p=>
                                <Grid item xs={12} md={12} lg={4} xl={4}>
                                    <Paper
                                        elevation={3}
                                        sx={{'&:hover':{bgcolor:'#f0f0f0'}}}
                                        onClick={()=>{setProduct(p);setOpen(true)}}
                                    >
                                            <div style={{display:'flex',flexDirection:'row',height:85}} >
                                                <div style={{width:90,height:90}}>
                                                    <img style={{width:90,height:90,borderRadius:10}} src={ROUTES.URLImagenes+'productos/'+p.imagen} alt={p.nombre}/>
                                                </div>
                                                <Box sx={{ml:1}}>
                                                    <Typography style={{fontWeight:'bold'}}>{p.nombre} </Typography>
                                                    <Typography style={{fontWeight:'bold'}}>{p.precio} $</Typography>
                                                    <Typography variant="caption">{p.unidad}</Typography>
                                                </Box>
                                                
                                            </div>
                                    </Paper>
                                </Grid>
                            )
                        }
                    </Grid>
                    </Container>
                </Box>
            }

            <Dialog
                open={open}
                fullWidth
                maxWidth='md'
                onClose={()=>setOpen(false)}
            >
                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'end'}}>
                    <IconButton aria-label="delete" onClick={()=>setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Grid container spacing={3}>
                    <Grid item lg={4} xl={4}>
                        <Box>
                            <img style={{width:'100%',height:'auto',borderRadius:10}} src={ROUTES.URLImagenes+'productos/'+product.imagen}/>
                        </Box>
                    </Grid>
                    <Grid item lg={8} xl={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Typography variant="h5">{product.nombre}</Typography>    
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Typography variant="h5">{product.precio} $</Typography>    
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1">{product.descripcion}</Typography>    
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            
        </Box>
    )
}