import React from 'react';
import { Box } from '@mui/system';
import { Container, Grid, Typography, Button } from '@mui/material';


import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useHistory } from 'react-router-dom';

export default function Resenas()
{
    const history = useHistory();
    const redirect = (route) => {
        history.push(route);
    }
    return(
        <Box 
            sx={{
                display: 'flex',
                minHeight:'70vh',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.default',
                color: '#000',
                p: 3,
            }}
        >
            <Container maxWidth='lg'>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box 
                            sx={{
                                width:{xs:300,sm:300,md:300, lg:550,xl:550},
                                height:{xs:175,sm:175,md:450, lg:390,xl:390}
                            }}
                        >
                            <iframe 
                                width="100%" height="100%" 
                                src="https://www.youtube.com/embed/gahdrQRhgcI" 
                                title="Reseñas"
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen
                            >
                            </iframe>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography variant='h3' color='primary' sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'},flexDirection:'row-reverse',textAlign:'end'}}>
                            Sistema de Reseñas <br/>¡Conoce de qué se trata!
                        </Typography>
                        <Typography variant='h5' color='primary' sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'},flexDirection:'row-reverse',textAlign:'end'}}>
                            Sistema de Reseñas <br/>¡Conoce de qué se trata!
                        </Typography>
                        <Typography variant="subtitle1" style={{marginTop:30,letterSpacing:1,textAlign:'end'}}>
                            Tu puedes ser parte<br/> 
                            de lo que está bien hecho<br/>
                            en Anzoátegui.
                        </Typography>

                        
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'end',marginTop:40}}>
                            
                            <Button variant='contained' onClick={()=>redirect('/resenas')}>
                                Saber más
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

//<iframe width="1093" height="615" src="https://www.youtube.com/embed/3eJptQz4jhM" title="Restaurante El Parador Del Puerto" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//<div style={{height: 460,width:640}} id="videoGastronomia"></div>