

/**
 * Estas Rutas son las rutas principales de la Main web de QUD
 * Habilitarlas antes de subir la actualizacion al servidor
 */

const URL = 'https://api.anzteenamora.com/';
const API = 'api/v1/';

const url = URL + API;
const URLImages = URL + 'apiate/public/storage/img/';

//AQUI TERMINAN LA RUTAS DE LA NUBE */

/**
 * Estas Rutas son las rutas en LOCAL de ATE
 * comentarlas antes de subir la actualizacion al servidor
*


//const URL = 'http://localhost/ate/public/';
const URL = 'http://127.0.0.1:8000/';
const API = 'api/v1/';

const url = URL + API;
const URLImages = URL + 'storage/img/';

 //AQUI TERMINAN LAS RUTAS PARA PRUEBAS LOCALES */


const ROUTES = {
    URLImagenes : URLImages,
    GOOGLE : 
    {
        GOOGLE_MAPS_APIKEY : 'AIzaSyDQSZlmEUm2SQtC82bzMD-ewSbtWYgx8og'
    },
    AUTH : 
    {
        CompanySignup : url + 'companysignup',
        PersonSignup : url + 'register',
        Login : url + 'login',
        Verify : url + 'register/email_verification',
        changePassword : url + 'passwordreset/changePassword'
    },
    PRODUCTOS :
    {
        GetProductos : url + 'productos/negocio/',
        GetForAdd : url + 'productos/forAdd',
        Add : url + 'productos/add',
        Update : url + 'productos/update',
        ChangeStatus : url + 'productos/changestatus',
        CountProducts : url + 'productos/nproductos'
    },
    UNIDADES : 
    {
        GetUnidades : url + 'unidades/'
    },
    CATEGORIAS : {
        GetAll : url + 'categorias/all',
        GetShops : url + 'categorias/all/',
        GetShopCategories : url + 'categorias/all/shop/',
        GetShopUser : url + 'categorias/all/shop/user/',    //completar con {idUsuario}
        GetShopProducts : url + 'categorias/all/shop/user/' //completar con {idUsuario}/{idSubCategoria}
    },
    SUBCATEGORIAS : {
        GetAll : url + 'subcategorias/all',
        Add : url + 'subcategorias/add',
        Update : url + 'subcategorias/update',
        ChangeStatus : url + 'subcategorias/changestatus'
    },
    PERFIL: {
        GetDatosEmpresa : url + 'perfil/shop/datos',
        UpdateDatosEmpresa : url + 'perfil/shop/datos/update',
        GetContactosEmpresa : url + 'perfil/shop/contactos',
        UpdateContactosEmpresa : url + 'perfil/shop/contactos/update',
        GetCoordenadas : url + 'perfil/shop/coordenadas',
        UpdateCoordenadas : url + 'perfil/shop/coordenadas/update',
        GetImagenes : url + 'perfil/shop/imagenes',
        UpdateImagenes : url + 'perfil/shop/imagenes/update'
    },
    REVIEWS : {
        
        GetCompany : url + 'review/company/',
        GetQuestions : url + 'review/questions/',
        WriteReview : url + 'review/send'
    },
    CIUDADES : {
        GetAll : url + 'public/ciudades/all',
        GetByID : url + 'public/ciudades/byid/', //completar con el ID de la ciudad
    },
    EVENTOS : {
        GetLast : url + 'public/events/last',
        GetByCity : url + 'public/events/ciudad/', //completar con el id de la ciudad
    },
    SHOPS : {
        Top5: url + 'public/review/top5',
        GetShops: url + 'public/shops/categorias/all/', //completar con el ID de la categoria
        GetShopsByCategoryName: url + 'public/shops/categorias/byname/', //completar con el nombre de la categoria
        GetShop: url + 'public/shops/shop',
        GetShopsByCity: url + 'public/categorias/ciudad/',//completar con el id de la ciudad
    },
    CONTACTUS:{
        SendContactUsEmail: url + 'public/sendContactUsEmail'
    },
    MENU:
    {
        GetSubCategories : url + 'categorias/all/shop/', //completar con el id o qr code del usuario
        GetShopCategoriesProducts: url + 'categorias/all/shop/user/', //completar con idUsuario + idSubCategoria
    }
}

export default ROUTES;