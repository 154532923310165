import { Box } from "@mui/system";

import backgroundImg from '../../../assets/images/eventos.jpg' //'../assets/images/mapaRevista.jpg';
import Events from ".";
import { Container, Grid, Paper, Typography } from "@mui/material";

import pasos from '../../../assets/images/resenas/pasos.png';
import dashboard from '../../../assets/images/resenas/dashboard.png';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import emprendedor from '../../../assets/images/paquetes/emprendedor.png';
import basico from '../../../assets/images/paquetes/basico.png';
import premium from '../../../assets/images/paquetes/premium.png';

import emprendedor2 from '../../../assets/images/paquetes/emprendedor2.png';
import basico2 from '../../../assets/images/paquetes/basico2.png';
import premium2 from '../../../assets/images/paquetes/premium2.png';


function ImgPlan(props)
{
    return(
        
            <Box sx={{
                    width:150,
                    height:150,
                    borderRadius:150,
                    bgcolor:'primary.main',
                    display:{xs:'flex',sm:'flex',md:'none',lg:'none',xl:'none'},
                    flexDirection:'row',
                    justifyContent:'center',alignItems:'center'
                }}
            >
                <img style={{width:90,height:60}} src={props.src} alt='Anzoategui Te Enamora'/>
            </Box>
        
    )
}

function Linea(props)
{
    return(
        props.rows.map((row)=>
            <div style={{marginTop:7}}>
                <Grid container spacing={3}>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <CheckBoxIcon color='primary'/>
                    </Grid>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Typography component='p' variant='body1'>{row}</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    )
}

function DescripcionPlan(props)
{
    return(
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{position:'relative'}}>
            <Paper sx={{padding:2}}>
            <ImgPlan src={props.src}/>
            <Box sx={{ display:{xs:'none',sm:'none',md:'flex',lg:'flex',xl:'flex'} }} style={{position:'absolute',top:0,left:0,right:0,bottom:0,margin:'auto'}}>
                <Box sx={{position:'relative',width:'100%',height:'100%'}}>
                    <img style={{opacity:'0.1',position:'absolute',top:0,left:0,right:0,bottom:0,margin:'auto',width:'30%'}} src={props.src2} alt={props.title}/>
                </Box>
            </Box>
            <Typography variant='h4' color='primary.main' sx={{fontWeight:'bold'}}>{props.title}</Typography>
            <br/>
            <Linea rows={props.rows}/>
            </Paper>
        </Grid>
        
        /**
        <Grid item lg={4}>
            <Paper sx={{padding:2}}>
                <ImgPlan src={props.src}/>
                <br/>
                <Typography component='h4' variant='h4' style={{textAlign:'center'}}>
                    {props.title}
                </Typography>
                <br/>
                <Linea rows={props.rows}/>
            </Paper>
        </Grid>
         */
    )
}


export default function Reviews()
{
    const rowsEmprendedor = [
        'Registro en la Plataforma Anzoátegui Te Enamora',
        'Foto de perfil y 5 imágenes para la galería',
        'Descripción del emprendimiento, ubicación, horario y actividades',
        'Redes sociales',
        'Código QR digital en PDF',
        'Stickers de Anzoátegui Te Enamora'
    ];

    const rowsBasico = [
        'Registro en la Plataforma Anzoátegui Te Enamora',
        'Foto de perfil y 6 imágenes para la galería',
        'Descripción del establecimiento comercial, ubicación, horario y actividades',
        'Redes sociales',
        'Ruta turística',
        'Código QR digital en PDF',
        'Stickers de Anzoátegui Te Enamora',
        'Un (1) código QR en acrílico para reseña',
        'una (1) mención al mes en la cuenta de Instagram de Anzoátegui Te Enamora'
    ];

    const rowsPremium = [
        'Items del plan Estándar',
        'Distintivo de Anzoátegui Te Enamora en acrílico',
        'Se cambia el código QR en acrílico, por otro código QR en acrílico plata para reseña',
        'Una (1) publicación por afiliación, en las historias de la cuenta de Instagram de Anzoátegui Te Enamora',
        'Dos (2) menciones al mes, en la cuenta de Instagram de Anzoátegui Te Enamora',
        'Acceso a promociones exclusivas para nuestros afiliados y aliados comerciales',
        'Acceso a panel administrativo de la plataforma Anzoátegui Te Enamora'
    ];

    return(
        <div>
            <Box
                sx={{
                    display: 'flex',
                    height:{xs:'20vh',sm:'40vh',md:'40vh',lg:'40vh',xl:'40vh'},
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 3,
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(" + backgroundImg + ")",
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100%'
                }}
            >
                
            </Box>
            
            <Container>
                <Typography
                    style={{textAlign:'center',marginTop:30,marginBottom:40,fontWeight:'bold'}}
                    variant='h3'
                    color='primary.main'
                >
                    Sistema de Reseñas
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{display:'flex',flex:1,justifyContent:'center',width:'100%'}}>
                            <img style={{width:'100%'}} src={pasos} alt='Anzoategui Te Enamora'/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Typography component='p' variant='h6'>
                            El Sistema de Reseñas de Anzoátegui Te Enamora, es una plataforma 
                            que nos ayuda a compartir información valiosa sobre un establecimiento, 
                            con otros usuarios que buscan un producto o servicio similar
                            <br/><br/>
                            Cuando visites hoteles, posadas, restaurantes
                            cafés y otros comercios que se encuentren en
                            la Región, podrás calificarlos de uno a cinco
                            corazones según tu experiencia, tomando en
                            cuenta la calidad, entorno, tiempo de espera,
                            servicio y otros indicadores que se consideren importantes. 

                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Box
                sx={{                   
                    mt:5,
                    p:5,
                    display:'flex',
                    flexDirection:'column',
                    background:'#2e3f6e',
                    color:'#FFF',
                    justifyContent:'center',
                    alignItems:'center'
                }}
            >
                <Typography variant='h4'>
                    Beneficios de ser parte del Sistema de Reseñas
                </Typography>
                
            </Box>

            <Container sx={{mt:5,mb:5}}>
                <Grid container spacing={1} sx={{display:'flex',flexDirection:{xs:'row-reverse',sm:'row-reverse',md:'row',lg:'row',xl:'row'}}}>
                    <Grid item xs={12} sm={7} md={7} lg={7} xl={7} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <CheckBoxIcon color='primary'/>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                <Typography component='p' variant='h6' style={{textAlign:'justify'}}>
                                    Mientras más y mejor puntuación tengas, tu negocio se posicionará 
                                    mucho mejor en relación a los demás que están en tu mismo rubro.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <CheckBoxIcon color='primary'/>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                <Typography component='p' variant='h6' style={{textAlign:'justify'}}>
                                    Tus clientes tendrán a mano toda la información de tu negocio como 
                                    los horarios de atención, ubicación y fotos.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <CheckBoxIcon color='primary'/>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                <Typography component='p' variant='h6' style={{textAlign:'justify'}}>
                                    Contarás con un panel de control donde podrás visualizar todas las 
                                    reseñas de tu negocio y así saber qué aspectos mejorar para tus clientes.
                                </Typography>
                            </Grid>
                        </Grid>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                <CheckBoxIcon color='primary'/>
                            </Grid>
                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                <Typography component='p' variant='h6' style={{textAlign:'justify'}}>
                                    Tendrás la opción de hacer presencia publicitaria en las demás plataformas 
                                    de Anzoátegui Te Enamora. 
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',height:'100%'}}>
                            <img style={{width:'100%'}} src={dashboard} alt='Anzoategui Te Enamora'/>
                        </div>
                    </Grid>
                </Grid>
            </Container>

            <Typography
                style={{textAlign:'center',marginTop:30,marginBottom:40,fontWeight:'bold'}}
                variant='h3'
                color='primary.main'
            >
                Nuestros Planes
            </Typography>
            <Container style={{paddingBottom:80}}>
                <Grid container spacing={3}>
                    <DescripcionPlan title='Emprendedor' src={emprendedor} src2={emprendedor2} rows={rowsEmprendedor} />
                    <DescripcionPlan title='Estándar' src={basico} src2={basico2} rows={rowsBasico} />
                    <DescripcionPlan title='Premium' src={premium} src2={premium2} rows={rowsPremium} />
                </Grid>
            </Container>
        </div>
    )
}