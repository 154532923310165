import { GET } from './ConsumeApiController'
import ROUTES from '../navigation/routes';

export async function get_categorias()
{
    let response = await GET(ROUTES.CATEGORIAS.GetAll);
    return response;
}


