import FigtreeTff from '../fonts/Figtree/static/Figtree-Regular.ttf';

const lightTheme = {
    
    palette : 
    {
        common:
        {
            black:'#000',
            white:'#FFF'
        },
        primary : 
        {
            light: '#0599DE',
            main : '#2e3f6e',//#0088CA //Anterior #2e3f6e
            dark: '#0076B6',
            contrastText:'#FFF'
        },
        secondary:
        {
            light: '#039BE5',
            main : '#0288D1',//'#0b0e11',
            dark: '#01579B',
            contrastText : '#FFF',
        },
        text:
        {
            primary:'#000',
            secondary:'#474D57',
            primaryTitle:'#EAECEF',
            primaryText:'#B7BDC6'
        },
        background : 
        {
            default:'#FAFAFA',
            main:'#FFF',
            paper : '#FAFAFA',
            paperDark : '#0B0E11',
            contrastBg:'#FCD535',
            appBar:'#FFF'
        }

    },
    components:
    {
        MuiPaper:
        {
            styleOverrides:{
                root:{
                    background:'#FFF',
                    "&:hover":{
                        cursor:'hand'
                    }
                },
            }
        },
    },
}

export default lightTheme;