import React, { useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import { Rating } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBoderIcon from '@mui/icons-material/FavoriteBorder';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled' : {
        color:'#2E3F6E'
    },
    '& .MuiRating-iconHover' : {
        color:'#2E3F6E'
    },
});

export default function StyledRatingComponent(props)
{
    return(
        <StyledRating
                name={props.name}
                id={props.id}
                defaultValue={props.value}
                getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                precision={0.5}
                value={props.value}
                //onChange={props.onChange}
                //onChange={(e) => {setValue(e.target.value);props.onChange(e)}}
                icon={<FavoriteIcon style={{fontSize:props.fontSize,color:props.color}}/>}
                emptyIcon={<FavoriteBoderIcon style={{fontSize:props.fontSize}} />}
            />
    )
}