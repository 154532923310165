import { GET } from './ConsumeApiController'
import ROUTES from '../navigation/routes';

export async function GetAllCities()
{
    let response = await GET(ROUTES.CIUDADES.GetAll);
    return response;
}


export async function GetCityByID(idCiudad)
{
    let response = await GET(ROUTES.CIUDADES.GetByID+idCiudad);
    return response;
}


