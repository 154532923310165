import React, { useEffect, useState } from 'react';
import { Box, Card, CardActionArea, Container, Grid, TextField, Typography } from '@mui/material';

import GoogleMapReact from 'google-map-react';
import { GetEventsByCity } from '../../../controllers/EventsController';
import CustomImageButton from '../../../components/ImageButton';
import StyledRatingComponent from '../../../components/styledRating';
import ROUTES from '../../../navigation/routes';
import { useHistory, useParams } from 'react-router-dom';
import { GetShopsByCity } from '../../../controllers/ShopsController';
import ShopComponent from '../../../components/ShopComponent';
import MapMarker from '../../../components/MapMarker';

import marker from '../../../assets/images/MarkerATE.png'

import backgroundImg from '../../../assets/images/ciudades.jpg'; //'../assets/images/mapaRevista.jpg';
import publicidad_tls from '../../../assets/images/publicidad/publicidad1.png';
import Publicidad1 from '../../../components/publicidad1';
import { GetCityByID } from '../../../controllers/CitiesControllers';

export default function City()
{
    const [city,setCity] = useState([]);
    const [events,setEvents] = useState([]);
    const [shops,setShops] = useState([]);

    const { idCiudad } = useParams();

    const history = useHistory();

    useEffect(()=>{
        window.scrollTo(0,0);
        getCity()
    },[]);

    const redireccionar = (route) => {
        history.push(route)
    }

    const getCity = async () => {
        let response = await GetCityByID(idCiudad);
        if(response.success === true)
        {
            setCity(response.data.ciudad);
            getEventos(idCiudad);
            getShops(idCiudad);
        }
    }

    const getEventos = async (idCiudad) => 
    {
        let response = await GetEventsByCity(idCiudad);
        if(response.success === true)
        {
            setEvents(response.data.eventos);
            console.log('response.data.eventos ',response.data.eventos);
        }
    }

    const getShops = async (idCiudad) => {
        let response = await GetShopsByCity(idCiudad);
        if(response.success === true)
        {
            setShops(response.data.negocios);
        }
    }

    return(
        <div>
            <Box
                sx={{
                    display: 'flex',
                    height:{xs:'20vh',sm:'40vh',md:'40vh',lg:'40vh',xl:'40vh'},
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 3,
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(" + backgroundImg + ")",
                    backgroundPosition:'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100%'
                }}
            >
            </Box>
            <Typography component='h2' variant="h2" color='primary.main' style={{textAlign:'center',fontWeight:'bold',marginBottom:20,marginTop:20}}>
                {city.nombre}
            </Typography>

            
            <Container maxWidth='lg'>
                <Grid container spacing={3} style={{marginTop:20}}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant='h6'>
                            Breve historia
                        </Typography>
                        <TextField
                            style={{width:'100%'}}
                            InputProps={{
                                readOnly: true,
                                inputProps:{
                                    style:{
                                        textAlign:'justify'
                                    }
                                }
                            }}
                            id="standard-multiline-static"
                            label=""
                            multiline
                            minRows={7}
                            //defaultValue="Default Value"
                            value={city.historia}
                            variant="standard"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        {/*<Publicidad1 src={publicidad_tls} alt='TLS'/>*/}

                        {/**Mapa */}
                        <Box
                            sx={{
                                display: 'flex',
                                height:'60vh',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'background.default',
                                color: 'text.primary',
                                //p: 3,
                            }}
                        >
                            {
                                city.length !== 0
                                ?
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyCm6IHco-Ma5uDw9AqUpJm8BHkbGz4DkNU" }}
                                    defaultCenter={{lat: parseFloat(city.latitud),lng: parseFloat(city.longitud)}}
                                    defaultZoom={14}
                                >
                                    {
                                        shops.length !== 0
                                        ?
                                        shops.map((shop)=>
                                            
                                            <MapMarker 
                                                ciudad={city.nombre}
                                                shop={shop}
                                                lat={parseFloat(shop.latitud)} 
                                                lng={parseFloat(shop.longitud)}
                                            />
                                        )
                                        :null
                                    }
                                </GoogleMapReact>
                                :null
                            }
                        </Box>
                    </Grid>
                </Grid>

                

                {/**Eventos */}
                <Grid container style={{marginTop:50}}>
                    <Typography variant='h6'>Eventos</Typography>
                    <Grid container spacing={3}>
                        {
                            events.length !== 0
                            ?
                            events.map((event,index) =>
                                <Grid key={index} item xs={12} lg={3}>
                                    <CustomImageButton
                                        nombre={event.nombre}
                                        img={event.img}
                                        ciudad={event.ciudad}
                                        descripcion={event.descripcion}
                                    />
                                </Grid>
                            )
                            :
                            <Grid item>
                                <Typography style={{color:'#7d7d7d'}}>No se encontraron resultados</Typography>
                            </Grid>
                        }                           
                    </Grid>
                </Grid>


                {/**ALIADOS */}
                {
                    shops.length !== 0
                    ?
                    <>
                        <Typography variant='h6' style={{marginTop:50}}>Lugares Turisticos</Typography>
                        <ShopComponent shops={shops} idCategoria={10}/>

                        <Typography variant='h6' style={{marginTop:50}}>Restaurantes</Typography>
                        <ShopComponent shops={shops} idCategoria={1}/>
                        

                        <Typography variant='h6' style={{marginTop:50}}>Hoteles</Typography>
                        <ShopComponent shops={shops} idCategoria={2}/>

                        <Typography variant='h6' style={{marginTop:50}}>Super Mercados</Typography>
                        <ShopComponent shops={shops} idCategoria={11}/>
                    </>
                    :null
                }
                
            </Container>
        </div>
    )
}