import React, { useEffect, useRef, useState} from 'react';
import { Autocomplete, Box, Button, Card, Container, FormControl, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import ROUTES from '../../../navigation/routes';
import { get_categorias } from '../../../controllers/CategoriasController';

import Shops from '../empresas/index';

import SearchIcon from '@mui/icons-material/Search';

function Categoria(props)
{
    return(
        <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
            <Button 
                variant='outlined'
                sx={{width:'100%',height:{xs:100,sm:100,md:150,lg:150,xl:150},flexDirection:'column'}}
                onClick={props.onClick}
                href='#categoria'
            >
                <div style={{width:60,height:60}}>
                    <img
                        style={{width:60,height:60}}
                        src={ROUTES.URLImagenes+'categorias/'+props.img}
                        alt='ATE'
                    />
                </div>
                <Typography
                    sx={{
                        fontSize:{xs:12,sm:13,md:15,lg:20,xl:20}
                    }}
                >
                    {props.nombre}
                </Typography>
            </Button>
        </Grid>
    )
}

export default function Categorias(props)
{
    const [categorias,setCategorias] = useState([]);
    const [categoriaText,setCategoriaText] = useState('');
    const [categoria,setCategoria] = useState([]);

    const [inputValue,setInputValue] = useState();


    useEffect(()=>{
        getCategorias();
    },[]);

    const getCategorias = async () => {
        let response = await get_categorias();
        if(response.success === true)
        {
            setCategorias(response.data.categorias);
        }
    }

    const handleClick = (idCategoria,nombre) => {
        setCategoria(nombre);
        setCategoriaText(nombre);
        
    }

    return(
        <div>
        <Box
            sx={{
                display:'flex',
                alignItems:'center',
                marginTop:10,
                marginBottom:3,
            }}
        >
            <Container maxWidth='lg'>
                <Typography color='primary.main' sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}} variant='h3' style={{textAlign:'center',fontWeight:'bold'}}>
                    Conoce nuestros aliados <br/>
                    ¡Explora un mundo de posibilidades!
                </Typography>
                <Typography color='primary.main' sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}} variant='h5' style={{textAlign:'center',fontWeight:'bold'}}>
                    Conoce nuestros aliados <br/>
                    ¡Explora un mundo de posibilidades!
                </Typography>

                <Typography style={{textAlign:'center',color:'#000',marginTop:20,marginBottom:40}}>Seleccione una categoría</Typography>

                <Grid container spacing={3} style={{justifyContent:'center'}}>
                    {
                        categorias.length !== 0
                        ?
                            categorias.map( (item) =>{
                                if (item.idcategoria !== 10)
                                return <Categoria onClick={() => handleClick(item.idcategoria,item.nombre) } nombre={item.nombre} img={item.img} id={item.idcategoria}/>
                            })
                        :null
                    }
                </Grid>
            </Container>
            
        </Box>
        <Container component='div' id='categoria' style={{paddingTop:80}}>
            {
                categoria !== 0
                ?<Shops category={categoria} />
                :null
            }
        </Container>
        </div>
    )

}